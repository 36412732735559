import initialState from './initialState';
import {
  FETCH_STUFF,
  RECEIVE_STUFF
} from '../actions/actionTypes';

export type Action = {
  type: 'FETCH_STUFF',
} | {
  type: 'RECEIVE_STUFF',
  stuff: typeof initialState.stuff
}

export default function stuff(state = initialState.stuff, action: Action) {
  let newState;
  
  switch (action.type) {
    case FETCH_STUFF: 
      console.log('fetch');
      return;
    case RECEIVE_STUFF:
      newState = action.stuff;
      console.log('receive');
      return newState;
    default: 
      return state;
  }
}