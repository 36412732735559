import React, { Component } from 'react';

import './css/BudgetSettings.css';
import { Budget } from './types/Budget';
import { SettingsPanelBarsObject } from './SettingsMenu';

type Props = {
  new: boolean,
  budget: Budget,
  setBudgetValue: (id: keyof SettingsPanelBarsObject, key: keyof Budget, value: any) => void,
  id: keyof SettingsPanelBarsObject,
  hiding: () => void,
  showing: () => void,
}

class TimerSettings extends Component<Props, {
  show: boolean,
}> {
  constructor(props: Props) {
    super(props);

    this.state = {
      show: this.props.new || false,
    }
  }

  renderOverview = () => (<div className="overview">
    <span><i className="fas fa-money-bill"></i>{this.props.budget.label}</span>
    <button onClick={this.show}><i className="fas fa-ellipsis-h"></i></button>
  </div>);

  renderEdit = () => (<div className="edit">
    <label htmlFor="label">Title</label>
    <input name="label" type="text" 
      value={this.props.budget.label}
      onChange={(event) => { this.props.setBudgetValue(this.props.id, 'label', event.target.value)}}></input>
    {this.props.new && <input name="label" type="number" 
      value={this.props.budget.allocated}
      onChange={(event) => this.props.setBudgetValue(this.props.id, 'allocated', parseInt(event.target.value))}></input>}
    <button className="done" onClick={this.hide}><i className="fas fa-check-circle"></i></button>
  </div>);

  hide = () => {
    this.props.hiding();
    this.setState({ show: false });
  }

  show = () => {
    this.props.showing();
    this.setState({ show: true });
  }

  render() {
    return <div className="timer-settings" key={this.props.id}>
      {this.state.show ? this.renderEdit() : this.renderOverview()}
    </div>;
  }
}

export default TimerSettings;
