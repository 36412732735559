import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as stuffActions from './actions/actions';

import Clock from './Clock';
import SettingsMenu from './SettingsMenu';

import './css/App.css';
import { Bar } from './types/Bar';
import { Timer } from './types/Timer';
import { Budget } from './types/Budget';
import { isTimer } from './helpers';

const colors = [
  '#f44336',
  '#2196F3',
  '#4CAF50',
  '#FFC107',
  '#607D8B',
  '#FF5722',
  '#673AB7'
]

export type BarsObject = {
  [i: number]: Bar
};

export type State = {
  bars: BarsObject,
  nextId: number,
  showSettings: boolean
}

type Props = {
  stuffActions: typeof stuffActions,
  stuffs: string[]
};

class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      bars: {},
      nextId: 0,
      showSettings: false,
    }
  }

  makeTimer = (until: Timer["until"], color: Timer["color"], label = ''): Timer => {
    return {
      id: null,
      until,
      start: moment(),
      progress: 0,
      color: color || colors[Math.floor(Math.random() * colors.length)],
      interval: null,
      label,
    };
  }

  addTimer = (until: Timer["until"], color: Timer["color"], label = '', start = true): number | { start: boolean } => {
    const timer = this.makeTimer(until, color, label);

    const id = this.state.nextId;
    timer.id = id;
    const timers = {
      [id]: timer,
      ...this.state.bars
    };

    this.setState({
      bars: timers,
      nextId: id + 1
    }, () => {
      if (start) {
        this.startTimer(id);
      }
    });

    return id;
  }

  componentDidMount = () => {
    this.setupBeforeUnloadListener();

    this.loadBars();
  }

  setupBeforeUnloadListener = () => {
    window.addEventListener("beforeunload", (ev) => {
      this.saveBars();
    });
  };

  startTimers = () => {
    Object.keys(this.state.bars)
      .map(id => this.state.bars[parseInt(id)])
      .filter(isTimer)
      .forEach(timer => { 
        if (!timer.id) {
          return;
        }
        this.startTimer(timer.id);
      });
  }

  loadBars = () => {
    const barsString = localStorage.getItem('bars');
    if (!barsString) {
      return;
    }
    const bars: BarsObject = JSON.parse(barsString);
    Object.keys(bars)
      .map(id => parseInt(id))
      .forEach(id => {
        const bar = bars[id];
        if (isTimer(bar)) {
          bar.until = moment(bar.until);
          bar.start = moment(bar.start);
        }
      });
    const nextId = parseInt(Object.keys(bars).sort().pop() || '-1') + 1;

    this.setState({ bars: bars, nextId }, this.startTimers);
  }

  saveBars = () => {
    Object.keys(this.state.bars).forEach(id => {
      if (isTimer(this.state.bars[parseInt(id)])) {
        this.stopTimer(parseInt(id))
      }
    });

    localStorage.setItem('bars', JSON.stringify(this.state.bars));
  }

  startTimer = (id: number) => {
    const timer = this.state.bars[id] as Timer;

    timer.interval = setInterval(() => this.updateTimer(id), 1000);

    const timers = {
      [id]: {
        timer,
        ...this.state.bars[id]
      },
      ...this.state.bars
    };


    this.setState({ bars: timers });
  }

  stopTimer = (id: number) => {
    const timer = this.state.bars[id] as Timer;

    const interval = timer.interval;
    if (interval !== null) {
      clearInterval(interval);
    }
  }

  setTimerValue = (id: number, key: keyof Timer, value: Timer[keyof Timer]) => {
    const timer = this.state.bars[id] as Timer;
    timer[key] = value;

    this.setState({
      bars: {
        [id]: timer,
        ...this.state.bars
      }
    });
  }


  setBudgetValue = (id: number, key: keyof Budget, value: Budget[keyof Budget]) => {
    const budget = this.state.bars[id] as Budget;
    budget[key] = value;

    budget.progress = budget.used /  budget.allocated;

    if (budget.progress >= 1) {
      const bars = {...this.state.bars};
      delete bars[id];
      return this.setState({ bars });
    }

    this.setState({
      bars: {
        [id]: budget,
        ...this.state.bars
      }
    });
  }

  updateTimer = (id: number) => {
    const timer = this.state.bars[id] as Timer;

    const now = moment();
    const totalDifference = moment(timer.until).diff(moment(timer.start));
    const elapsed = moment(timer.until).diff(now);

    timer.progress = parseFloat(((totalDifference - elapsed) / totalDifference).toFixed(2));

    if (now.isSameOrAfter(timer.until)) {
      this.stopTimer(id);

      const bars = {...this.state.bars};
      delete bars[id];
      return this.setState({ bars });
    }

    this.setState({
      bars: {
        [id]: timer,
        ...this.state.bars
      }
    })
  }

  makeBudget = (amount: number, color: Budget["color"], label: Budget["label"]):Budget => {
    return {
      id: null,
      allocated: amount,
      used: 0,
      color,
      label,
      progress: 0
    }
  }

  addBudget = (budget: Budget): number | { start: boolean } => {
    const id = this.state.nextId;
    budget.id = id;
    const bars = {
      [id]: budget,
      ...this.state.bars
    };

    this.setState({
      bars: bars,
      nextId: id + 1
    });

    return id;
  }
  
  render() {
    return (
      <div className="app">
        {/* <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
          }}>
          <button onClick={this.props.stuffActions.appendStuff}>Chur buddas</button>
          <pre>{JSON.stringify(this.props.stuffs, null, 2)}</pre>
        </div> */}  
        <button 
          className={`${this.state.showSettings ? '' : 'inactive'} settings-button`} 
          onClick={() => this.setState({ showSettings: !this.state.showSettings })}
        ><i className="fas fa-cog"></i></button>
        <Clock 
          bars={this.state.bars}
          setBudgetValue={this.setBudgetValue}
          setTimerValue={this.setTimerValue} 
         />
        <SettingsMenu 
          makeTimer={this.makeTimer}
          addTimer={this.addTimer}
          makeBudget={this.makeBudget}
          addBudget={this.addBudget}
          bars={this.state.bars}
          setBudgetValue={this.setBudgetValue}
          setTimerValue={this.setTimerValue} 
          show={this.state.showSettings} />
      </div>
    );
  }
}

export type MakeTimerFunction = App["makeTimer"];
export type AddTimerFunction = App["addTimer"];
export type MakeBudgetFunction = App["makeBudget"];
export type AddBudgetFunction = App["addBudget"];
export type SetTimerValueFunction = App["setTimerValue"];
export type SetBudgetValueFunction = App["setBudgetValue"];

function mapStateToProps(state: { stuff: string[] }) {
  return {
    stuffs: state.stuff
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    stuffActions: bindActionCreators(stuffActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
