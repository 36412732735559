import React, { Component, MouseEvent } from 'react';
import Bar from './Bar';
import { Budget } from '../types/Budget';
import { SetBudgetValueFunction } from '../App';

type Props = {
budget: Budget,
  setBudgetValue: SetBudgetValueFunction
};

const asMoney = (amount: number) => {
  return `$${amount.toFixed(2)}`;
}

class BudgetBar extends Component<Props, {}> {
  handleClick = (event: MouseEvent) => {
    if (this.props.budget.id === null) { return; }

    event.preventDefault();
    
    this.props.setBudgetValue(
      this.props.budget.id,
      "used",
      this.props.budget.used + 10
    )
  }

  render() {
    return (
      <Bar onClick={this.handleClick} bar={this.props.budget}>
        <div>{this.props.budget.label}</div>
        <div>Used {asMoney(this.props.budget.used)} of {asMoney(this.props.budget.allocated)}</div>
      </Bar>
    );
  }
}

export default BudgetBar;
