import React, { Component, DOMAttributes } from 'react';
import { Bar as IBar } from '../types/Bar';

class Bar extends Component<{
  bar: IBar
  onClick?: DOMAttributes<HTMLDivElement>["onClick"]
}, {}> {
  render() {
    return (
      <div className="bar" onClick={this.props.onClick} style={{
        height: (100 - (100 * this.props.bar.progress)) + '%',
        backgroundColor: this.props.bar.color,
        transition: 'height 0.3s ease'
      }}>
      {this.props.children || this.props.bar.label}
      </div>
    );
  }
}

export default Bar;
