import React, { Component } from 'react';
import moment from 'moment';
import TimerSettings from './TimerSettings';
import BudgetSettings from './BudgetSettings';
import { isBudget, isTimer } from './helpers';

import './css/SettingsPanel.css';
import { MakeTimerFunction, AddTimerFunction, BarsObject, MakeBudgetFunction, SetTimerValueFunction, AddBudgetFunction, SetBudgetValueFunction } from './App';
import { Timer } from './types/Timer';
import { Budget } from './types/Budget';
import { Bar } from './types/Bar';

const NEW_TIMER_ID = -1;
const NEW_BUDGET_ID = -2;

export type SettingsPanelBarsObject = {
  [i: number]: Bar
}

type Props = {
  show: boolean,
  makeTimer: MakeTimerFunction,
  addTimer: AddTimerFunction,
  makeBudget: MakeBudgetFunction,
  addBudget: AddBudgetFunction,
  setBudgetValue: SetBudgetValueFunction,
  setTimerValue: SetTimerValueFunction,
  bars: BarsObject
};

type State = {
  newTimer: Timer | null,
  newBudget: Budget | null,
  shouldOpenNewTimer: boolean,
  shouldOpenNewBudget: boolean,
};

class SettingsMenu extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      newTimer: null,
      newBudget: null,
      shouldOpenNewTimer: false,
      shouldOpenNewBudget: false,
    };
  }

  styleObject = () => {
    return {
      transform: `translate3d(${this.props.show ? '0' : '100'}%, 0, 0)`
    }
  }

  beginEditingNewTimer = () => {
    this.setState({
      newTimer: this.props.makeTimer(
        moment().add(1, 'minute'),
        '#af2244',
        'New Timer'
      ),
      shouldOpenNewTimer: true,
    });
  }

  beginEditingNewBudget = () => {
    this.setState({
      newBudget: this.props.makeBudget(
        100,
        '#FFC107',
        'New Budget'
      ),
      shouldOpenNewBudget: true,
    });
  }

  bars(): SettingsPanelBarsObject {
    const bars = { ...this.props.bars };
    if (this.state.newTimer) {
      bars[NEW_TIMER_ID] = this.state.newTimer;
    }
    if (this.state.newBudget) {
      bars[NEW_BUDGET_ID] = this.state.newBudget;
    }

    return bars;
  };

  budgetSettings = (id: keyof SettingsPanelBarsObject, budget: Budget) => {
    const makeHidingHandler = (id: keyof SettingsPanelBarsObject) => {
      const budget = this.state.newBudget;
      if (id !== NEW_BUDGET_ID || budget === null) {
        return () => {}
      }

      return () => {
        this.props.addBudget(budget);
        this.setState({
          newBudget: null
        });
      }
    }

    const setValue = (id: keyof SettingsPanelBarsObject, key: keyof Budget, value: any) => {
      if (id !== NEW_BUDGET_ID) {
        return this.props.setBudgetValue(id, key, value);
      }

      if (this.state.newBudget === null) {
        return;
      }

      this.setState({
        newBudget: {
          ...this.state.newBudget,
          [key]: value
        }
      });
    }

    return <BudgetSettings 
      key={id} 
      id={id} 
      budget={budget}
      hiding={makeHidingHandler(id)}
      showing={() => {}}
      setBudgetValue={setValue}
      new={id === NEW_BUDGET_ID} 
    />
  
  }

  timerSettings = (id: keyof SettingsPanelBarsObject, timer: Timer) => {
    const makeHidingHandler = (id: keyof SettingsPanelBarsObject) => {
      const timer = this.state.newTimer;
      if (id !== NEW_TIMER_ID || timer === null) {
        return () => {}
      }

      return () => {
        this.props.addTimer(timer.until, timer.color, timer.label);
        this.setState({
          newTimer: null
        });
      }
    }

    const setValue = (id: keyof SettingsPanelBarsObject, key: keyof Timer, value: any) => {
      if (id !== NEW_TIMER_ID) {
        return this.props.setTimerValue(id, key, value);
      }

      if (this.state.newTimer === null) {
        return;
      }

      this.setState({
        newTimer: {
          ...this.state.newTimer,
          [key]: value
        }
      });
    }

    return <TimerSettings 
      key={id} 
      id={id} 
      timer={timer} 
      hiding={makeHidingHandler(id)}
      showing={() => {}}
      setTimerValue={setValue}
      startShown={id === NEW_TIMER_ID} 
    />
  }

  render() {
    const bars = this.bars();

    return (
      <div className="settings-panel" style={this.styleObject()}>
        <h2>Bar settings</h2>
        {Object.keys(bars).map(id => {
          const barId = parseInt(id);
          const bar = bars[barId];
          
          if (isTimer(bar)) {
            return this.timerSettings(barId, bar);
          } else if (isBudget(bar)) {
            return this.budgetSettings(barId, bar);
          }

          return null;
        })}
        <button className="new" onClick={this.beginEditingNewTimer}>Timer <i className="fas fa-plus"></i></button>
        <button className="new" onClick={this.beginEditingNewBudget}>Budget <i className="fas fa-plus"></i></button>
      </div>
    );
  }
}

export default SettingsMenu;
