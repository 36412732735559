import {
  createStore, 
  applyMiddleware,
  compose
} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers/rootReducer';

declare const window: Window & { '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__': any };

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore() {
  return createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
  )
};
