import * as types from './actionTypes';
import initialState from '../reducers/initialState';
import { ActionCreator, Action } from 'redux';

export const receiveStuff: ActionCreator<Action> = (json: {
  stuff: string[]
}) => {
  return {
    type: types.RECEIVE_STUFF,
    stuff: json.stuff
  }
};

export const appendStuff = () => {
  return (dispatch: any, getState: () => typeof initialState) => {
    const { stuff } = getState();
    dispatch(receiveStuff({
      stuff: stuff.concat('more stuff')
    }))
  }
}

export function fetchStuff() {
  return (dispatch: any) => {
    setTimeout(() => {
      dispatch(receiveStuff({
        stuff: ['diddly']
      }))
    });
  };
};