import React, { Component } from 'react';
import Bar from './Bars/Bar';
import { SetTimerValueFunction, SetBudgetValueFunction } from './App';
import { isBudget } from './helpers';

import './css/Clock.css';
import { BarsObject } from './App';
import BudgetBar from './Bars/BudgetBar';

class Clock extends Component<{
  bars: BarsObject,
  setBudgetValue: SetBudgetValueFunction,
  setTimerValue: SetTimerValueFunction
}, {}> {
  renderTimers() {
    return Object.keys(this.props.bars).map(key => {
      const bar = this.props.bars[parseInt(key)];
      if (isBudget(bar)) {
        return <BudgetBar key={key} setBudgetValue={this.props.setBudgetValue} budget={bar} />
      }

      return <Bar key={key} bar={bar} /> 
    });
  }

  render() {
    return (
      <div className="clock">
        {/* <pre>{JSON.stringify(this.props.timers, null, 2)}</pre> */}
        {this.renderTimers()}
      </div>
    );
  }
}

export default Clock;
