import { Budget } from './types/Budget';
import { Timer } from './types/Timer';
import { Bar } from './types/Bar';


export function isTimer(bar: Bar): bar is Timer {
  return (bar as Timer).start !== undefined;
}

export function isBudget(bar: Bar): bar is Budget {
  return (bar as Budget).allocated !== undefined;
}