import React, { Component } from 'react';

import './css/TimerSettings.css';
import { Timer } from './types/Timer';
import { SettingsPanelBarsObject } from './SettingsMenu';

type Props = {
  startShown?: boolean,
  timer: Timer,
  setTimerValue: (id: keyof SettingsPanelBarsObject, key: keyof Timer, value: any) => void,
  id: keyof SettingsPanelBarsObject,
  hiding: () => void,
  showing: () => void,
}

class TimerSettings extends Component<Props, {
  show: boolean
}> {
  constructor(props: Props) {
    super(props);

    this.state = {
      show: this.props.startShown || false,
    }
  }

  renderOverview = () => (<div className="overview">
    <span><i className="fas fa-clock"></i>{this.props.timer.label}</span>
    <button onClick={this.show}><i className="fas fa-ellipsis-h"></i></button>
  </div>);

  renderEdit = () => (<div className="edit">
    <div className="row">
      <label htmlFor="label">Title</label>
      <button className="done" onClick={this.hide}><i className="fas fa-check-circle"></i></button>
    </div>
    <input name="label" type="text" 
      value={this.props.timer.label}
      onChange={(event) => { this.props.setTimerValue(this.props.id, 'label', event.target.value)}}></input>
  </div>);

  hide = () => {
    this.props.hiding();
    this.setState({ show: false });
  }

  show = () => {
    this.props.showing();
    this.setState({ show: true });
  }

  render() {
    return <div className="timer-settings" key={this.props.id}>
      {this.state.show ? this.renderEdit() : this.renderOverview()}
    </div>;
  }
}

export default TimerSettings;
